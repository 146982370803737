<template>
    <div class="profile-page">
        <div class="wrapper">
          <div class="page-header page-header-small" filter-color="blue">
            <div class="page-header-image" data-parallax="true" style="background-image: url('../assets/images/cc-bg-2.jpg')"></div>
            <div class="container">
              <div class="content-center">
                <div class="cc-profile-image"><a href="#">
                  <img src="../assets/images/zsolt.png" alt="Image"/></a></div>
                <div class="h2 title">Mészáros Zsolt</div>
                <p class="category text-white">Web programozó és Szoftverfejlesztő</p>
                <a class="btn btn-primary smooth-scroll mr-2" href="#contact" data-aos="zoom-in" data-aos-anchor="data-aos-anchor">Írj nekem</a>
                <a class="btn btn-primary" href="../assets/pdf/cv.pdf" data-aos="zoom-in" data-aos-anchor="data-aos-anchor">Cv letöltése</a>
              </div>
            </div>
            <div class="section">
              <div class="container">
                <div class="button-container">
                  <a class="btn btn-default btn-round btn-lg btn-icon" href="https://www.facebook.com/meszaros.zsolt.165/" rel="tooltip" title="Follow me on Facebook"><i class="fa fa-facebook"></i></a>
                  <a class="btn btn-default btn-round btn-lg btn-icon" href="https://twitter.com/zsorzso0214" rel="tooltip" title="Follow me on Twitter"><i class="fa fa-twitter"></i></a>
                  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'Profile',

}
</script>
