<template>
  <footer class="footer">
    <div class="container text-center">
      <a class="cc-facebook btn btn-link" href="https://www.facebook.com/meszaros.zsolt.165/"><i class="fa fa-facebook fa-2x " aria-hidden="true"></i></a>
      <a class="cc-twitter btn btn-link " href="https://twitter.com/zsorzso0214"><i class="fa fa-twitter fa-2x " aria-hidden="true"></i></a>
    </div>
    <div class="h4 title text-center">Zsolt Meszaros</div>
    <div class="text-center text-muted">
      <p>&copy; meszaroszsolt.hu</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
