export default 
[
    {
        date: "2023",
        educationName: "Google",
        education: "Google Cybersecurity",
        liBody:
        [
            "Foundations of Cybersecurity",
            "Play It Safe: Manage Security Risks",
            "Connect and Protect",
            "Networks and Network Security",
            "Tools of the Trade: Linux and SQL",
            "Assets, Threats, and Vulnerabilities",
            "Sound the Alarm: Detection and Response",
            "Automate Cybersecurity Tasks with Python",
            "Put It to Work: Prepare for Cybersecurity Jobs"
        ], 
        pbody: "Those who earn the Google Cybersecurity Certificate have completed eight courses, developed by Google, that include hands-on, practice based assessments and are designed to prepare them for entry-level roles in cybersecurity. They are competent in beginner-level Python, Linux, SQL, Security Information and Event Management (SIEM) tools, and Intrusion Detection Systems (IDS). They know how to identify common cybersecurity risks, threats, and vulnerabilities, as well as the techniques to mitigate.",
        link:"../assets/maincert/Coursera_JSZ362PLUKAR.pdf",
        linkName:"Google Cybersecurity",
        helpy: "Link: "
    },
    {
        date: "2017",
        educationName: "SAP",
        education: "Developing Web Apps with SAPUI5",
        liBody:
        [
            "Become a Data Binding Expert",
            "Create Responsive Apps",
            "Master SAPUI5"
        ], 
        pbody: "",
        link:"../assets/pdf/ui51-RecordOfAchievement.pdf",
        linkName:"Developing Web Apps with SAPUI5",
        helpy: "Link: "
    },
    {
        date: "2008 - (nem lett befejezve)",
        educationName: "Nyíregyházi Főiskola",
        education: "Programtervező Informatikus", 
        pbody: "Nyelvvizsga miatt nincs befejezve. De több mint 10 éves szakmai tapasztalatom van ami nagyban tudja pótolni a diplomát.",
        liBody:[],
        link:"",
        linkName:"",
        helpy:""
    },
]