<template>
  <header>
    <div class="profile-page sidebar-collapse">
      <nav class="navbar navbar-expand-lg fixed-top navbar-transparent bg-primary" color-on-scroll="400">
        <div class="container">
          <div class="navbar-translate"><a class="navbar-brand" href="#" rel="tooltip">Mészáros Zsolt CV</a>
            <button class="navbar-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-bar bar1"></span><span class="navbar-toggler-bar bar2"></span><span class="navbar-toggler-bar bar3"></span></button>
          </div>
          <div class="collapse navbar-collapse justify-content-end" id="navigation">
            <ul class="navbar-nav">
              <li class="nav-item"><a class="nav-link smooth-scroll" href="#about">Rólam</a></li>
              <li class="nav-item"><a class="nav-link smooth-scroll" href="#skill">Készségek</a></li>
              <!--<li class="nav-item"><a class="nav-link smooth-scroll" href="#portfolio">Portfólió</a></li>-->
              <li class="nav-item"><a class="nav-link smooth-scroll" href="#experience">Tapasztalat</a></li>
              <li class="nav-item"><a class="nav-link smooth-scroll" href="#contact">Kapcsolat</a></li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
